import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getWorkplaces = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/workplaces`);
    return response.data;
  } catch (error) {
    //console.error("Error fetching workplaces:", error);
    throw error;
  }
};

export const addNewWorkplace = async (workplace) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/workplaces`, workplace);
    return response.data;
  } catch (error) {
    //console.error("Error adding workplace:", error);
    throw error;
  }
};

export const addReview = async (workplaceId, reviewData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/workplaces/${workplaceId}/add-review`,
      reviewData
    );
    return response.data;
  } catch (error) {
    //console.error("Error adding review:", error);
    throw error;
  }
};

export const getCoordinatesService = async (address) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/coordinates`, {
      params: { address },
    });

    return response.data;
  } catch (error) {
    //console.error("Error adding review:", error);
    throw error;
  }
};

export const getAddressSuggestions = async (query) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/address-suggestions`, {
      params: { query },
    });

    return response.data;
  } catch (error) {
    //console.error("Error getting address suggestions:", error);
    throw error;
  }
};

export const getFilteredWorkplaces = async (filtersData) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/filtered-workplaces`, {
      params: filtersData,
    });

    return response.data;
  } catch (error) {
    //console.error("Error getting address suggestions:", error);
    throw error;
  }
};